<template>
  <div class="profile-header px-4 pt-2 pb-2 pb-sm-4">
    <!-- Profile info -->
    <div class="profile-header__block mt-2">
      <div class="profile-avatar avatar">
        <img
          :src="currentProfile.profile_pic_url"
          itemprop="image"
          :alt="currentProfile.first_name"
          :title="currentProfile.first_name"
          @error="onLogoImageError($event)"
        />
      </div>
      <div class="flex-column ml-0">
        <div class="profile-header__block flex-middle flex-wrap">
          <div class="profile-name mr-0 mr-sm-2">
            {{ getFullName(currentProfile) }}
          </div>
          <div
            v-if="currentProfile.is_verified"
            class="profile-verified mr-0 mr-sm-2 d-none d-sm-block"
          ></div>
          <div
            v-else
            class="profile-unverified mr-0 mr-sm-2 d-none d-sm-block"
          ></div>
          <div class="profile-username">@{{ currentProfile.username }}</div>
        </div>
        <text-highlight class="profile-bio" :queries="highlightedKeyword">{{
          currentProfile.biography
        }}</text-highlight>
        <div class="profile-bottom mt-2">
          <a v-show="getMainEmail(currentProfile)" class="profile-email">
            <i class="fas fa-envelope mr-1"></i>
            <input
              ref="emailBox"
              type="text"
              onselectstart="return false"
              onpaste="return false;"
              onCopy="return false"
              onCut="return false"
              onDrag="return false"
              onDrop="return false"
              autocomplete="off"
              value="Nothing"
            />
          </a>
        </div>
        <div class="profile-bottom mt-2">
          <div class="profile-location" v-if="currentProfile.location_country">
            <i class="fas fa-map-marker-alt"></i>
            {{ getFullLocation(currentProfile) }}
          </div>
          <div>
            <a
              v-if="currentProfile.website"
              :href="currentProfile.website"
              target="_blank"
              class="profile-site mr-0 mr-sm-3"
            >
              <i class="fas fa-globe"></i>
              <span>{{ currentProfile.website }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextHighlight from "vue-text-highlight";

import InstagramLeadProfile from "../../models/leadProfile";
import {
  getFullName,
  getFullLocation,
  getMainEmail,
  onLogoImageError
  //   getEngagementRateClass,
  //   getPermissionErrorMessage
} from "../../utils/index";

export default {
  name: "instagram-lead-profile-header",
  components: {
    TextHighlight
  },
  props: {
    currentProfile: {
      type: InstagramLeadProfile
    },
    highlightedKeyword: {
      type: String
    }
  },
  mounted() {
    //probably prevent scrapping
    this.$nextTick(() => {
      this.$refs.emailBox.value = getMainEmail(this.currentProfile);
      this.$refs.emailBox.addEventListener(
        "contextmenu",
        this.preventMailBoxContextMenu
      );
    });
  },
  methods: {
    onLogoImageError: onLogoImageError,
    getFullName: getFullName,
    getFullLocation: getFullLocation,
    getMainEmail: getMainEmail
  }
};
</script>

<style lang="scss" scoped>
</style>