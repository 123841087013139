<template>
  <div class="profile-header px-4 pt-2 pb-2 pb-sm-2">
    <div class="profile-header__block mt-2">
      <div class="profile-avatar avatar">
        <img
          :src="currentProfile.covers_small[0]"
          itemprop="image"
          :alt="currentProfile.nickname"
          :title="currentProfile.nickname"
          @error="onLogoImageError($event)"
        />
      </div>
      <div class="flex-column ml-0">
        <div class="profile-header__block flex-middle flex-wrap">
          <div class="profile-name mr-0 mr-sm-2">
            {{ currentProfile.nickname }}
          </div>
          <div
            v-if="currentProfile.is_verified"
            class="profile-verified mr-0 mr-sm-2 d-none d-sm-block"
          ></div>
          <div
            v-else
            class="profile-unverified mr-0 mr-sm-2 d-none d-sm-block"
          ></div>
          <div class="profile-username">@{{ currentProfile.username }}</div>
        </div>
        <text-highlight class="profile-bio" :queries="highlightedKeyword">{{
          currentProfile.signature
        }}</text-highlight>
      </div>
    </div>
  </div>
</template>

<script>
import TextHighlight from "vue-text-highlight";

import TikTokLeadProfile from "../../models/leadProfile";

import {
  asDefaultPic,
  onLogoImageError
  //   getEngagementRateClass,
  //   getPermissionErrorMessage
} from "../../utils/index";

export default {
  name: "tiktok-lead-profile-header",
  components: {
    TextHighlight
  },
  props: {
    currentProfile: {
      type: TikTokLeadProfile
    },
    highlightedKeyword: {
      type: String
    }
  },
  mounted() {
    //probably prevent scrapping - blank for possible email addresses displaying
    this.$nextTick(() => {
      //console.log("Tiktok highlightedKeyword", this.highlightedKeyword);
    });
  },
  methods: {
    onLogoImageError: onLogoImageError,
    asDefaultPic: asDefaultPic
  }
};
</script>

<style lang="scss" scoped>
</style>