<template>
  <div class="profile-panel">
    <!-- instagram -->
    <template v-if="social == socials.instagram.id">
      <instagram-lead-profile-header
        :currentProfile="currentProfile"
        :highlightedKeyword="highlightedKeyword"
      >
      </instagram-lead-profile-header>
    </template>

    <!-- tiktok -->
    <template v-if="social == socials.tiktok.id">
      <tiktok-lead-profile-header
        :currentProfile="currentProfile"
        :highlightedKeyword="highlightedKeyword"
      >
      </tiktok-lead-profile-header>
    </template>

    <!-- youtube -->
    <template v-if="social == socials.youtube.id">
      <youtube-lead-profile-header
        :currentProfile="currentProfile"
        :highlightedKeyword="highlightedKeyword"
      >
      </youtube-lead-profile-header>
    </template>
  </div>
</template>

<script>
import { SOCIALS } from "@/constants";

import InstagramLeadProfileHeader from "./InstagramLeadProfileHeader.vue";
import TiktokLeadProfileHeader from "./TiktokLeadProfileHeader.vue";
import YoutubeLeadProfileHeader from "./YoutubeLeadProfileHeader.vue";

import LeadProfile from "../../models/leadProfile";

export default {
  name: "lead-profile-header",
  components: {
    InstagramLeadProfileHeader,
    TiktokLeadProfileHeader,
    YoutubeLeadProfileHeader
  },
  props: {
    social: {
        type: String
    },
    currentProfile: {
      type: LeadProfile
    },
    highlightedKeyword: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
        socials: SOCIALS,
    };
  },
  computed: {
    // social() {
    //     console.log(this.currentProfile);
    //   return this.currentProfile.social;
    // }
  }
};
</script>

<style lang="scss">
  //@import "../../assets/scss/custom/profile-panel";
</style>