<template>
  <div class="profile-header px-4 pt-2 pb-2 pb-sm-4">
    <div class="profile-header__block mt-2">
      <div class="profile-avatar avatar">
        <img
          :src="currentProfile.avatar_url"
          itemprop="image"
          :alt="currentProfile.title"
          :title="currentProfile.title"
          @error="onLogoImageError($event)"
        />
      </div>

      <div class="flex-column ml-0 ml-sm-3">
        <div class="profile-header__block flex-middle flex-wrap">
          <div class="profile-name mr-0 mr-sm-2">
            {{ currentProfile.title }}
          </div>
          <div
            v-if="currentProfile.is_verified"
            class="profile-verified mr-0 mr-sm-2 d-none d-sm-block"
          ></div>
          <div
            v-else
            class="profile-unverified mr-0 mr-sm-2 d-none d-sm-block"
          ></div>
          <div class="profile-username">@{{ currentProfile.id }}</div>
        </div>

        <text-highlight class="profile-bio" :queries="highlightedKeyword">{{
          currentProfile.description
        }}</text-highlight>

        <div class="my-3">
          <span v-if="currentProfile.country" class="mr-0 mr-sm-3">
            <span class="material-icons-outlined md-18">location_on</span>
            <span>{{ currentProfile.country }}</span>
          </span>
          <a
            v-if="currentProfile.website"
            :href="currentProfile.website"
            target="_blank"
            class="profile-site mr-0 mr-sm-3"
          >
            <i class="fas fa-globe"></i>
            <span>{{ currentProfile.website }}</span>
          </a>
          <a v-show="getMainEmail(currentProfile)" class="profile-email">
            <i class="fas fa-envelope mr-1"></i>
            <input
              ref="emailBox"
              type="text"
              onselectstart="return false"
              onpaste="return false;"
              onCopy="return false"
              onCut="return false"
              onDrag="return false"
              onDrop="return false"
              autocomplete="off"
              value="Nothing"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextHighlight from "vue-text-highlight";

import YoutubeLeadProfile from "../../models/leadProfile";

import {
  asDefaultPic,
  onLogoImageError,
  getMainEmail
  //   getEngagementRateClass,
  //   getPermissionErrorMessage
} from "../../utils/index";

export default {
  name: "tiktok-lead-profile-header",
  components: {
    TextHighlight
  },
  props: {
    currentProfile: {
      type: YoutubeLeadProfile
    },
    highlightedKeyword: {
      type: String
    }
  },
  mounted() {
    //probably prevent scrapping - blank for possible email addresses displaying
    this.$nextTick(() => {
      //console.log("Tiktok highlightedKeyword", this.highlightedKeyword);
    });
  },
  methods: {
    onLogoImageError: onLogoImageError,
    asDefaultPic: asDefaultPic,
    getMainEmail: getMainEmail
  }
};
</script>

<style lang="scss" scoped>
</style>